<template>
  <div>
    <Dialog
      :buttonLoader="downloadInvoiceLoader"
      :modal="downloadInvoiceDialog"
      @close-clicked="closeDownloadInvoiceDialog()"
      @save-clicked="downloadInvoice()"
    >
      <template v-slot:header> Confirmation </template>
      <template v-slot:content>
        Are you sure you want to download invoice ?
      </template>
      <template v-slot:buttonHeading> Confirm </template>
    </Dialog>
    <v-card flat class="bg-none mt-7">
      <v-container class="listing"
        ><span class="fs-20 fw-500 f-avenir ls-0"> Billing History</span>
        <v-divider class="my-4 borderColor"></v-divider>
        <v-data-table
          :headers="headers"
          :items="billingHistory"
          :loading="loader"
          fixed-header
          max-height="300px"
          class="pa-30 pt-0 temper-pagination"
          :class="$vuetify.breakpoint.xs && 'pa-0'"
        >
          <template v-slot:[`item.amountPaid`]="{ item }">
            <span class="fs-13 f-avenir textPrimary--text">
              {{ PRICESOBJ[item?.paymentCurrency] }}
              {{ item?.amountPaid }}</span
            >
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            <span class="fs-13 fw-400 f-avenir textPrimary--text">
              {{ item.createdAt | formatDate }}
              -
              {{ item.createdAt | formatTime }}</span
            >
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <span
              class="text-capitalize fw-600 fs-13 f-inter ls-0 ma-0 pa-0 primary--text"
              style="cursor: pointer"
              @click="downloadInvoiceDialogFunction(item)"
              >Download Invoice</span
            >
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import pagination from "../../components/common/Pagination.vue";
import ApiService from "../../services/Api";
import UrlServices from "../../services/Url";
import { mapGetters } from "vuex";
import Dialog from "../common/dialog-box/index.vue";
export default {
  components: {
    pagination,
    Dialog,
  },
  data() {
    return {
      billingHistory: [],
      paginate: {
        itemsPerPage: 10,
        page: 1,
        lastPage: 10,
        total: 100,
      },
      loader: false,
      headers: [
        {
          text: "Date",
          sortable: false,
          value: "createdAt",
        },
        { text: "Details", value: "packageId.planTitle", sortable: false },
        {
          text: "Amount",
          value: "amountPaid",
          sortable: false,
        },
        { text: "Download", value: "action", sortable: false },
      ],
      downloadInvoiceLoader: false,
      downloadInvoiceDialog: false,
      downloadInvoiceDialogItem: null,
      PRICESOBJ: {
        USD: "$",
        GBP: "£",
        CAD: "$",
      },
    };
  },
  mounted() {
    this.fetchBillingHistory();
  },

  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
    }),
  },
  methods: {
    pageNumberChanged(value) {
      if (!value) value = 10;
      this.paginate.itemsPerPage = value;
      this.fetchBillingHistory();
    },
    // Method for fetching Billing History
    async fetchBillingHistory(pgNo) {
      try {
        if (!pgNo) pgNo = 1;
        this.loader = true;
        const response = await ApiService.GetRequest(
          `${UrlServices.fetchBillingHistory}`,
          {
            workspaceId: this.currentWorkspace._id,
            page: pgNo,
            size: this.paginate.itemsPerPage,
          }
        );
        this.billingHistory = response?.data?.data?.package;
        this.loader = false;
      } catch (error) {
        console.log("error here", error);
      }
    },
    // Method for downloading Invoice
    downloadInvoiceDialogFunction(item) {
      // if (item.packageId.planTitle === "Free")
      //   return this.$showNotificationBar("error", "This is a free package");
      if (!item.invoiceId) {
        return this.$showNotificationBar(
          "error",
          "Invoice does not exists for such plan"
        );
      }
      this.downloadInvoiceDialog = true;
      this.downloadInvoiceDialogItem = item;
    },
    async downloadInvoice() {
      try {
        this.downloadInvoiceLoader = true;
        const response = await ApiService.GetRequest(
          `${UrlServices.downloadInvoice}`,
          {
            invoiceId: this.downloadInvoiceDialogItem.invoiceId,
          }
        );
        window.open(response?.data?.data?.invoicePdf, "_blank");
        this.$showNotificationBar(
          "success",
          "Invoice has been downloaded successfully"
        );
        this.closeDownloadInvoiceDialog();
      } catch (error) {
        this.$showNotificationBar("error", error);
        this.closeDownloadInvoiceDialog();
      }
    },
    closeDownloadInvoiceDialog() {
      this.downloadInvoiceDialog = false;
      this.downloadInvoiceDialogItem = null;
      this.downloadInvoiceLoader = false;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  :deep .v-data-footer {
    margin-right: 0 !important;
    padding: 0 !important;
  }
  :deep .v-data-footer__select {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  :deep .v-data-footer__pagination {
    margin: 0 !important;
  }
  :deep .v-data-footer__select .v-select {
    margin: 13px 15px 13px 10px !important;
  }
  :deep .v-btn--icon.v-size--default {
    height: 36px;
    width: 15px;
    margin-left: 15px !important
  }
}

.profile-card {
  background-color: var(--v-inputBackground-base) !important;
  border-radius: 5px;
}
.cancel-subscription {
  height: 31.04px;
  border: 1px solid var(--v-CancelRed-base) !important;
  border-radius: 6px;
  color: var(--v-CancelRed-base) !important;
  font-weight: 600 !important;
}
.pass-dialog {
  border-radius: 7px !important;
  background-color: var(--v-whiteColor-base) !important;
  height: 458px !important;
}
:deep .v-btn--is-elevated {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 0%) !important;
}
.pass-btn {
  border-radius: 10px !important;
  width: 299px !important;
  height: 46px !important;
  text-transform: none !important;
}
.otp-input >>> .v-text-field__slot {
  background: var(--v-buttonBg-base) !important;
  border: 0.3px solid var(--v-primary-base) !important;
  border-radius: 6px !important;
}
:deep .v-input__slot {
  margin-bottom: 0 !important;
}
.default-selection-dropdown .v-select__slot label {
  z-index: 1;
}
.default-selection-dropdown .v-select__selections {
  padding-top: 5px !important;
}
.default-selection-dropdown .v-text-field__slot {
  margin-top: 10px;
}

/* when edit personal info then disabled btn css  */
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #f5f2fd !important;
  color: var(--v-primary-base) !important;
}

.subscription-plan {
  color: var(--v-primary-base) !important;
}
.subscription-status {
  color: var(--v-successColor-base) !important;
}

.listing {
  border: 1px solid #e8ebf4 !important;
  border-radius: 5px !important;
  padding: 18px !important;
}
.payment-plans {
  overflow-x: auto !important;
}
.add-payment {
  border: 1px solid #e8ebf4;
  border-radius: 8px;
  height: 142px;
  width: 278px;
}
</style>
