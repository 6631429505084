<template>
  <div class="ml-3">
    <Dialog
      :buttonLoader="cancelSubscriptionDialog.loader"
      :modal="cancelSubscriptionDialog.modal"
      @close-clicked="cancelSubscriptionDialogCloseClicked()"
      @save-clicked="cancelSubscriptionDialogSaveClicked()"
    >
      <template v-slot:header> Confirmation </template>
      <template v-slot:content>
        Are you sure you want to cancel Current Subscription ?
      </template>
      <template v-slot:buttonHeading> Confirm </template>
    </Dialog>
    <div
      :class="$vuetify.breakpoint.xs && 'mr-12'"
      v-if="!subscriptionApiLoader.loader && !subscriptionApiLoader.noResponse"
    >
      <!-- Main profile -->
      <v-card flat class="profile-card my-3 rounded-lg">
        <v-container>
          <v-card-title class="d-flex justify-space-between">
            <p class="ma-0 fs-20 fw-500 f-avenir ls-0" align="left">
              Subscription Info
              <v-chip
                v-if="currentWorkspace.partnerPackage"
                class="ma-2 pa-2"
                color="primary white--text"
                small
              >
                Partner Organisation
              </v-chip>
            </p>
            <v-btn
              v-if="
                subscriptionInfo[2].value === 'active' &&
                subscriptionPackage?.packageId?.key !== 'Free'
              "
              :loading="cancelSubscriptionLoader"
              class="text-capitalize cancel-subscription fs-13 f-avenir ls-0"
              @click="cancelSubscription()"
              >Cancel Subscription</v-btn
            >
          </v-card-title>
          <v-divider class="mx-4 borderColor"></v-divider>

          <v-row class="ma-0 pa-0 pa-4">
            <v-col
              v-for="(item, index) in subscriptionInfo"
              :key="index"
              class="ma-0 pa-0 mb-2"
              :cols="$vuetify.breakpoint.lgAndUp ? 3 : 12"
            >
              <p class="ma-0 fs-13 fw-400 f-avenir textPrimary--text ls-0 mt-1">
                {{ item.name }}
              </p>
              <div>
                <p
                  v-if="item.type === 'date'"
                  class="ma-0 fs-13 fw-600 sun f-avenir textPrimary--text ls-0"
                >
                  {{ item.value | formatDate }}
                </p>
                <p
                  v-else
                  class="ma-0 fs-13 fw-600 sun f-avenir ls-0 text-capitalize"
                  :class="
                    item.value == 'active'
                      ? 'successColor--text'
                      : 'primary--text'
                  "
                >
                  {{ item.value }}
                </p>
              </div>
            </v-col>
          </v-row>
          <span
            v-if="
              subscriptionPackage &&
              subscriptionPackage.downgrageOnNextBillingDate
            "
            class="px-4 fs-13 fw-600 sun f-avenir errorColor--text ls-0"
          >
            Your subscription is scheduled to expire, your plan will be
            automatically converted to the
            {{ subscriptionPackage.downgradeToPackage }} version.</span
          >
          <span
            v-else-if="
              subscriptionPackage &&
              subscriptionPackage.stripeSubscriptionStatus === 'cancel'
            "
            class="px-4 fs-13 fw-600 sun f-avenir errorColor--text ls-0"
          >
            Your subscription has been canceled, and your organisation will
            expire on the next billing date.</span
          >
        </v-container>
      </v-card>
      <!-- plans -->
      <div class="mt-12">
        <div class="d-flex">
          <span class="d-flex align-end fs-20 fw-500 f-avenir ls-0 mr-6">
            Plans
          </span>
          <span
            class="d-flex align-center fs-12 fw-300 f-avenir ls-0 pt-2"
            :class="monthlyCheck ? 'primary--text fw-600' : ''"
          >
            Monthly
          </span>
          <v-btn
            icon
            color="primary"
            class="pt-2"
            :ripple="false"
            @click="monthlyCheck = !monthlyCheck"
          >
            <v-icon :ripple="false">{{
              !monthlyCheck ? "mdi-toggle-switch" : "mdi-toggle-switch-off"
            }}</v-icon>
          </v-btn>
          <span
            class="d-flex align-center fs-12 fw-300 f-avenir ls-0 pt-2"
            :class="!monthlyCheck ? 'primary--text fw-600' : ''"
          >
            Yearly
          </span>
        </div>
        <div class="d-flex flex-row payment-plans">
          <div v-for="(plan, index) in paymentPlans" :key="index">
            <Plans
              :plan="plan"
              :monthlyCheck="monthlyCheck"
              @change-plan="changePlan"
              :readOnly="currentWorkspace.partnerPackage"
            />
          </div>
        </div>
      </div>

      <PromotionalCode @set-promo-code-key="setPromoCodeKey" />
      <!-- auto renewal  -->
      <!-- <div class="my-14">
      <div>
        <span class="fs-20 f-inter secondary--text">Enable auto renew</span>
        <v-btn
          icon
          :color="autoRenew ? 'primary' : 'inActiveColor'"
          @click="autoRenew = !autoRenew"
          :ripple="false"
        >
          <v-icon :ripple="false">{{
            autoRenew ? "mdi-toggle-switch" : "mdi-toggle-switch-off"
          }}</v-icon>
        </v-btn>
      </div>
      <span class="fs-14">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate
        libero et velit interdum, ac aliquet odio mattis. Class aptent taciti
        sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
      </span>
    </div> -->
      <div class="mt-12">
        <span class="fs-20 fw-500 f-avenir ls-0"> Payment Method </span>
        <div
          v-if="paymentMethods.length > 0"
          class="d-flex flex-row payment-plans"
        >
          <div v-for="(card, index) in paymentMethods" :key="index">
            <PaymentMethods :card="card" />
          </div>
        </div>
        <div
          v-else
          class="add-payment d-flex justify-center align-center mt-3 cursor-pointer"
          :style="$vuetify.breakpoint.xs && 'width: 100% !important'"
          @click="addNewCard()"
        >
          <img src="@/assets/integration/PlusCircle.svg" alt="" />
        </div>
      </div>

      <!-- transactions -->
      <BillingHistory />
    </div>
    <!-- If there is any issue in API or waiting for API to load -->
    <DataLoader
      v-else
      :check="subscriptionApiLoader"
      qty="3"
      portalName="Subscription"
    />
  </div>
</template>

<script>
import FormInput from "../../common/FormInput.vue";
import { mapGetters } from "vuex";
import pagination from "../../common/Pagination.vue";
import EventBus from "../../../js/EventBus";
import Plans from "../Plans.vue";
import PaymentMethods from "../PaymentMethods.vue";
import ApiService from "../../../services/Api";
import UrlServices from "../../../services/Url";
import BillingHistory from "../BillingHistory.vue";
import DataLoader from "../DataLoader.vue";
import Dialog from "../../common/dialog-box/index.vue";
import PromotionalCode from "../PromotionalCodeScreen.vue";
export default {
  components: {
    FormInput,
    pagination,
    Plans,
    PaymentMethods,
    BillingHistory,
    DataLoader,
    Dialog,
    PromotionalCode,
  },
  data() {
    return {
      cancelSubscriptionLoader: false,
      cancelSubscriptionDialog: {
        loader: false,
        modal: false,
      },
      subscriptionApiLoader: {
        loader: true,
        noResponse: true,
      },
      subscriptionPackage: null,
      autoRenew: false,
      subscriptionInfo: [
        {
          name: "Subscription Date",
          value: null,
          type: "date",
          key: "startDate",
        },
        {
          name: "Next Billing Date",
          value: null,
          type: "date",
          key: "endDate",
        },
        {
          name: "Subscription Status",
          value: null,
          type: "status",
          key: "status",
        },

        // {
        //   name: "Credit Card",
        //   value: "**** 2356",
        // },
        // {
        //   name: "Card Expiry",
        //   value: "03/27",
        // },
        // {
        //   name: "Next Billing Date",
        //   value: "30 November 2022",
        // },
      ],
      paymentMethods: [],
      changePlanCheck: false,
      monthlyCheck: true,
      promoCode: null,
    };
  },
  async mounted() {
    await this.getAllDetails();
  },

  computed: {
    ...mapGetters({
      paymentPlans: "user/getPaymentPlans",
      currentWorkspace: "auth/getCurrentWorkspace",
    }),
  },
  methods: {
    /*
     ** Get All subscription details using promise all
     */
    async getAllDetails() {
      try {
        const currentSubscription = ApiService.GetRequest(
          UrlServices.getProfile,
          {
            workspaceId: this.currentWorkspace._id,
          }
        );
        const plans = ApiService.GetRequest(UrlServices.getPlans);
        const paymentMethods = ApiService.GetRequest(
          UrlServices.getPaymentMethods,
          {
            workspaceId: this.currentWorkspace._id,
          }
        );
        const promises = [currentSubscription, plans, paymentMethods];
        const response = await Promise.all(promises);
        const subscriptionPackage = this.getCurrentSubscription(response[0]);
        this.getPlans(response[1], subscriptionPackage);
        this.getPaymentMethods(response[2], subscriptionPackage);
        this.setMonthlyCheck(subscriptionPackage);
        this.subscriptionPackage = subscriptionPackage;
        this.subscriptionApiLoader.loader = false;
        this.subscriptionApiLoader.noResponse = false;
      } catch (error) {
        this.subscriptionApiLoader.loader = false;
        this.subscriptionApiLoader.noResponse = true;
        console.log("error here", error);
      }
    },
    /*
     ** Get Current Subscription
     */
    getCurrentSubscription(response) {
      const subscriptionPackage = response.data.user.package;
      if (subscriptionPackage) {
        this.subscriptionInfo.forEach((item) => {
          item.value = subscriptionPackage[item.key];
        });
      }
      return subscriptionPackage;
    },
    /*
     ** Get Plans from DB
     */
    getPlans(response, subscriptionPackage) {
      const plans = response.data.data;
      const modifiedPlan = plans.map((item) => {
        item.buttonLoader = false;
        item.subscribed = false;
        if (
          item._id === subscriptionPackage?.packageId?._id &&
          subscriptionPackage.status === "active"
        )
          item.subscribed = true;
        return item;
      });

      // Sort the array by the 'subscribed' key
      modifiedPlan.sort((a, b) => {
        if (a.subscribed && !b.subscribed) {
          return -1; // a should be placed before b
        } else if (!a.subscribed && b.subscribed) {
          return 1; // a should be placed after b
        } else {
          return 0; // the order doesn't need to change
        }
      });

      this.$store.commit("user/setPaymentPlan", modifiedPlan);
    },
    /*
     ** Get Payment Methods
     */
    getPaymentMethods(response) {
      this.paymentMethods = response.data.data ? response.data.data : [];
      // Sort the array by the 'subscribed' key
      this.paymentMethods.sort((a, b) => {
        if (a?.currentlyActive && !b?.currentlyActive) {
          return -1; // a should be placed before b
        } else if (!a?.currentlyActive && b?.currentlyActive) {
          return 1; // a should be placed after b
        } else {
          return 0; // the order doesn't need to change
        }
      });
      // Remove duplicates
      const unique = [];
      for (const item of this.paymentMethods) {
        const duplicate = unique.find(
          (obj) => obj.card.fingerprint === item.card.fingerprint
        );
        if (!duplicate) {
          unique.push(item);
        }
      }
      this.paymentMethods = unique;
    },

    addNewCard() {
      this.$showNotificationBar(
        "success",
        "Currently for adding card, you need to move on to checkout page"
      );
    },
    /*
     ** Cancel Subscription open Dialog
     */
    cancelSubscription() {
      try {
        this.cancelSubscriptionLoader = true;
        /*
         ** Check for free subscription
         */
        if (this.subscriptionPackage?.packageId?.key === "Free") {
          return this.cancelSubscriptionSnackBar(
            "error",
            "Free Package can't be unsubscribed"
          );
        }
        /*
         ** Check if subscription is already cancelled
         */
        if (this.subscriptionPackage?.stripeSubscriptionStatus === "cancel") {
          return this.cancelSubscriptionSnackBar(
            "error",
            "Current Subscription is already cancelled"
          );
        }
        /*
         ** Open consent Dialog
         */
        this.cancelSubscriptionDialog.modal = true;
        /*
         ** Stop Loader
         */
        this.cancelSubscriptionLoader = false;
      } catch (error) {
        console.log("error here", error);
      }
    },
    /*
     ** Cancel Subscription Error Handler Function
     */
    cancelSubscriptionSnackBar(type, message) {
      this.$showNotificationBar(type, message);
      this.cancelSubscriptionLoader = false;
    },
    /*
     ** Cancel Subscription Reset Values on Dialog Close Click
     */
    cancelSubscriptionDialogCloseClicked() {
      this.cancelSubscriptionDialog.modal = false;
      this.cancelSubscriptionDialog.loader = false;
    },
    /*
     ** Cancel Subscription API
     */
    async cancelSubscriptionDialogSaveClicked() {
      try {
        this.cancelSubscriptionDialog.loader = true;
        /*
         ** Calling API for changing behaviour
         */
        const payload = {
          workspaceId: this.currentWorkspace._id,
          subscriptionId: this.subscriptionPackage?.subscriptionId,
          status: "cancel", // by-default we are placing only cancel subscription
        };
        await ApiService.PostRequest(UrlServices.cancelSubscription, payload);
        /*
         ** Show snackbar for cancelling
         */
        this.cancelSubscriptionSnackBar(
          "success",
          "Subscription has been cancelled successfully."
        );
        /*
         ** Update stripe Subscription to cancel
         */
        this.subscriptionPackage.stripeSubscriptionStatus = "cancel";
        /*
         ** Reset Dialog Properties
         */
        this.cancelSubscriptionDialogCloseClicked();
      } catch (error) {
        console.log("error here", error);
        this.cancelSubscriptionSnackBar(
          "error",
          error.response.data.message ? error.response.data.message : error
        );
        this.cancelSubscriptionDialogCloseClicked();
      }
    },
    async changePlan(plan, index) {
      try {
        /*
         ** Check if any Plan API has called
         */
        if (this.changePlanCheck) {
          return this.changePlanSnackBar(
            "error",
            "Change Plan has already called"
          );
        }
        plan.buttonLoader = true;
        this.changePlanCheck = true;
        /*
         ** Check for already subscribed plan
         */
        if (plan.subscribed) {
          return this.changePlanSnackBar(
            "error",
            "This plan is already subscribed",
            plan
          );
        }
        /*
         ** Calling API for changing plan
         */
        const payload = {
          workspaceId: this.currentWorkspace._id,
          lookup_key:
            plan.key === "Free" ? null : plan.planPricing[index].stripePriceId, // currently passing sampleID for this
          ...(this.promoCode && { promoCode: this.promoCode }),
        };
        const checkoutSessionResponse = await ApiService.PostRequest(
          UrlServices.createCheckoutSession,
          payload
        );
        if (checkoutSessionResponse.data.status === "downgrade") {
          this.subscriptionPackage = null;
          this.subscriptionApiLoader.loader = true;
          this.subscriptionApiLoader.noResponse = true;
          this.changePlanCheck = false;
          this.getAllDetails();
        } else {
          this.changePlanSnackBar(
            "success",
            "Checkout Session has been created",
            plan
          );
          window.open(checkoutSessionResponse.data.data, "__blank");
        }
      } catch (error) {
        this.changePlanSnackBar(
          "error",
          error.response.data.message ? error.response.data.message : error,
          plan
        );
      }
    },
    changePlanSnackBar(type, message, plan) {
      this.$showNotificationBar(type, message);
      if (plan) {
        plan.buttonLoader = false;
      }
      this.changePlanCheck = false;
    },
    /*
     ** Set Monthly Check
     */
    setMonthlyCheck(plan) {
      if (plan.packageId.key === "Free") return;
      const currentPlan = plan.packageId.planPricing.find(
        (item) => item._id === plan.packagePlanId
      );
      if (currentPlan?.monthly == false) this.monthlyCheck = false;
      return;
    },
    /*
     ** Set Promo Code Key
     */
    setPromoCodeKey(promoCode) {
      this.promoCode = promoCode;
    },
  },
};
</script>

<style scoped>
.profile-card {
  background-color: var(--v-inputBackground-base) !important;
  border-radius: 5px;
}
.cancel-subscription {
  height: 31.04px;
  border: 1px solid var(--v-CancelRed-base) !important;
  border-radius: 6px;
  color: var(--v-CancelRed-base) !important;
  font-weight: 600 !important;
}
.pass-dialog {
  border-radius: 7px !important;
  background-color: var(--v-whiteColor-base) !important;
  height: 458px !important;
}
:deep .v-btn--is-elevated {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 0%) !important;
}
.pass-btn {
  border-radius: 10px !important;
  width: 299px !important;
  height: 46px !important;
  text-transform: none !important;
}
.otp-input >>> .v-text-field__slot {
  background: var(--v-buttonBg-base) !important;
  border: 0.3px solid var(--v-primary-base) !important;
  border-radius: 6px !important;
}
:deep .v-input__slot {
  margin-bottom: 0 !important;
}
.default-selection-dropdown .v-select__slot label {
  z-index: 1;
}
.default-selection-dropdown .v-select__selections {
  padding-top: 5px !important;
}
.default-selection-dropdown .v-text-field__slot {
  margin-top: 10px;
}

/* when edit personal info then disabled btn css  */
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #f5f2fd !important;
  color: var(--v-primary-base) !important;
}

.subscription-plan {
  color: var(--v-primary-base) !important;
}
.subscription-status {
  color: var(--v-successColor-base) !important;
}

.listing {
  border: 1px solid #e8ebf4 !important;
  border-radius: 5px !important;
  padding: 18px !important;
}
.payment-plans {
  overflow-x: auto !important;
}
.add-payment {
  border: 1px solid #e8ebf4;
  border-radius: 8px;
  height: 142px;
  width: 278px;
}
</style>
