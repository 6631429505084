<template>
  <v-card flat class="ma-0 pa-0 bg-none mt-3 rounded-lg mr-3 pb-3">
    <img
      src="../../assets/integration/CheckCircle.svg"
      class="check"
      alt=""
      v-if="plan.subscribed"
    />
    <div
      class="pl-0 pr-0 pt-4"
      :class="plan.subscribed ? 'subscribed inputBackground' : 'not-subscribed'"
    >
      <div class="pa-4 pt-0 pb-0 d-flex justify-space-between">
        <div class="d-flex flex-column">
          <span
            class="fs-17 fw-500"
            :class="plan.subscribed ? 'primary--text' : ''"
          >
            {{ plan.planTitle | truncate(10) }}
          </span>
          <span class="fs-13 fw-400">{{
            plan.description | truncate(30)
          }}</span>
        </div>
        <div v-if="plan.price != 0" class="text-right">
          <span class="fs-13 fw-500"
            >{{ planUserCurrency }}
            {{
              monthlyCheck
                ? plan.planPricing[planUserPrice].price
                : (plan.planPricing[planUserPrice].price / 12).toFixed(0)
            }}</span
          >
          <span class="fs-13">/month</span>
          <span
            v-if="!monthlyCheck"
            class="fs-10 fw-200 d-flex justify-center primary--text"
          >
            {{ planUserCurrency }}
            {{ plan.planPricing[planUserPrice].price }} billed annually</span
          >
        </div>
      </div>
      <div v-if="!readOnly" class="mt-4 ml-4">
        <v-btn
          outlined
          class="text-capitalize fw-400 fs-13 f-inter mr-4"
          :class="plan.subscribed ? 'cancel-subscription' : 'subscribe-now'"
          v-model="plan.subscribed"
          @click="changePaymentPlan(plan, planUserPrice)"
          :loading="plan.buttonLoader"
          >{{ plan.subscribed ? "Subscribed" : "Subscribe Now" }}</v-btn
        >
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      PRICESARRAY: ["USD", "GBP", "CAD"],
      PRICESOBJ: {
        USD: "$",
        GBP: "£",
        CAD: "$",
      },
    };
  },
  props: {
    plan: Object,
    buttonLoader: Boolean,
    monthlyCheck: Boolean,
    readOnly: Boolean,
  },
  methods: {
    changePaymentPlan(plan, index) {
      this.$emit("change-plan", plan, index);
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
    planUserPrice() {
      let userCurrency = Array.isArray(this.user.currency)
        ? this.user.currency[0]
        : this.user.currency;
      if (!this.PRICESARRAY.includes(userCurrency)) userCurrency = "USD";
      let index = this.plan.planPricing.findIndex(
        (item) =>
          item.currency === userCurrency && item.monthly === this.monthlyCheck
      );
      return index;
    },
    planUserCurrency() {
      let userCurrency = Array.isArray(this.user.currency)
        ? this.user.currency[0]
        : this.user.currency;
      if (!this.PRICESARRAY.includes(userCurrency)) userCurrency = "USD";
      return this.PRICESOBJ[userCurrency];
    },
  },
};
</script>

<style scoped>
.subscribed {
  border: 1px solid var(--v-primary-base) !important;
  border-radius: 5px !important;
  width: 372px;
  max-width: 372px;
  height: 142px;
}
.not-subscribed {
  border: 1px solid var(--v-borderColor-base) !important;
  border-radius: 5px !important;
  width: 372px;
  height: 142px;
  max-width: 372px;
}

.cancel-subscription {
  height: 31.04px;
  width: 131.26px;
  border: none !important;
  border-radius: 6px;
  color: var(--v-primary-base) !important;
  letter-spacing: 0px !important;
  font-weight: 600 !important;
  pointer-events: none;
}
.subscribe-now {
  height: 31.04px;
  /* width: 131px; */
  border: 1px solid var(--v-primary-base) !important;
  border-radius: 6px;
  color: var(--v-primary-base) !important;
  letter-spacing: 0px !important;
  font-weight: 600 !important;
}
.check {
  position: relative;

  float: right;
  bottom: 14px;
  left: 12px;
}
</style>
