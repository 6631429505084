<template>
  <v-card flat class="ma-0 pa-0 bg-none mt-3 rounded-lg mr-3 pb-3">
    <img
      v-if="card?.currentlyActive"
      src="../../assets/integration/CheckCircle.svg"
      class="check"
      alt=""
    />
    <div
      class="pl-0 pr-0 pt-4"
      :class="
        card?.currentlyActive ? 'subscribed inputBackground' : 'not-subscribed'
      "
    >
      <div class="pa-4 pt-0 pb-0">
        <div class="d-flex flex-column">
          <span
            class="fs-15 fw-400 ls-0 text-capitalize"
            :class="card?.currentlyActive ? 'primary--text' : ''"
          >
            {{ card.type }}
          </span>
          <div class="d-flex justify-space-between">
            <span class="d-flex align-center">
              <v-img
                v-if="paymentCard[card?.card?.brand]"
                class="image-dimension"
                :src="
                  require(`@/assets/payment/${paymentCard[card?.card?.brand]}`)
                "
                :lazy-src="
                  require(`@/assets/payment/${paymentCard[card?.card?.brand]}`)
                "
              ></v-img>
              <v-img
                v-else
                class="image-dimension"
                :src="require(`@/assets/payment/other.png`)"
                :lazy-src="require(`@/assets/payment/other.png`)"
              ></v-img>
            </span>
            <span class="d-flex align-end fs-13 mt-2 fw-500">
              **** **** **** {{ card?.card?.last4 }}</span
            >
          </div>
        </div>
      </div>
      <div class="mt-4 ml-4">
        <!-- <v-btn
          outlined
          class="text-capitalize fw-900 fs-13 f-inter mr-4 cancel-subscription"
          @click="changePaymentPlan(card)"
          v-model="card.type"
          >{{ !card?.subscribed ? "Remove Card" : "Subscribe Now" }}</v-btn
        > -->
        <!-- :class="!card?.subscribed ? 'cancel-subscription' : 'subscribe-now'" -->
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    card: Object,
  },
  data() {
    return {
      paymentCard: {
        visa: "visa.png",
        mastercard: "mastercard.png",
        paypak: "paypak.png",
      },
    };
  },

  methods: {
    changePaymentPlan(plan) {
      this.$emit("change-plan", plan);
    },
  },
};
</script>

<style scoped>
.subscribed {
  border: 1px solid var(--v-primary-base) !important;
  border-radius: 5px !important;
  width: 242px;
  height: 143px;
}
.not-subscribed {
  border: 1px solid var(--v-borderColor-base) !important;
  border-radius: 5px !important;
  width: 242px;
  height: 143px;
}

.cancel-subscription {
  height: 31.04px;
  width: 131.26px;
  border: none !important;
  border-radius: 6px;
  color: var(--v-primary-base) !important;
  letter-spacing: 0px !important;
  font-weight: 600 !important;
  pointer-events: none;
}
.subscribe-now {
  height: 31.04px;
  border: 1px solid var(--v-primary-base) !important;
  border-radius: 6px;
  color: var(--v-primary-base) !important;
  letter-spacing: 0px !important;
  font-weight: 600 !important;
}
.check {
  position: relative;

  float: right;
  bottom: 14px;
  left: 12px;
}
.image-dimension {
  width: 40px;
  min-width: 40px;
  object-fit: cover;
}
</style>
