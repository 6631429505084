<template>
  <div class="mt-12">
    <span class="fs-20 fw-500 f-avenir ls-0"> Promotional Codes </span>
    <div class="fs-13 fw-600 sun f-avenir errorColor--text ls-0">
      Note : Promotional codes are applicable only for upgrades and new
      subscriptions, and cannot be applied to downgrades.
    </div>
    <div :class="$vuetify.breakpoint.mdAndUp ? 'd-flex' : ''" class="my-4">
      <v-form ref="form">
        <v-text-field
          outlined
          flat
          dense
          solo
          autocomplete="off"
          class="text-no-wrap rounded-lg textPrimary--text fw-500 fs-12 custom-placeholder"
          v-model="textField.model"
          :prepend-inner-icon="textField.icon"
          :placeholder="textField.placeholder"
          clearable
          :rules="textField.rules"
          filled
          style="min-width: 280px; max-width: 303px"
          ></v-text-field>
          <!-- style="min-width: 280px; max-width: 280px" -->
        </v-form>
      <Button
        @button-clicked="validateCoupon()"
        class="mx-3"
        :class="$vuetify.breakpoint.xs && 'ml-0'"
        color="primary"
        style="margin-top: 2px"
        :loader="btnLoading"
      >
        <template v-slot:buttonHeading>
          <p class="ma-0 pa-0 offWhite--text" style="letter-spacing: -0.3px">
            Apply
          </p>
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "../common/button/index.vue";
import ApiService from "../../services/Api";
import UrlServices from "../../services/Url";
export default {
  components: {
    Button,
  },
  data() {
    return {
      btnLoading: false,
      textField: {
        model: "",
        icon: "mdi-gift",
        placeholder: "Enter Promo Code here",
        rules: [
          (val) => !!val || "Field is required",
          (val) =>
            (val && val.length < 30) ||
            "The length must be less than 30 characters",
        ],
      },
    };
  },
  mounted() {},

  computed: {},
  methods: {
    async validateCoupon() {
      try {
        if (this.btnLoading === true) return;
        if (!this.$refs.form.validate()) return;
        this.btnLoading = true;
        /*
         ** Calling API for Validating Promotional Coupon
         */
        const response = await ApiService.GetRequest(
          `${UrlServices.validateCoupon}?promoCode=${this.textField.model}`
        );
        /*
         ** Show snackbar
         */
        this.$showNotificationBar("success", "Coupon applied successfully");
        /*
         ** Change button Loading status to false
         */
        this.btnLoading = false;
        /*
         ** Emit data from child to parent and sent this coupon in checkout page
         */
        this.$emit("set-promo-code-key", this.textField.model);
      } catch (error) {
        this.btnLoading = false;
        this.$emit("set-promo-code-key", null);
        this.$showNotificationBar(
          "error",
          error?.response?.data?.error ? error.response.data.error : error
        );
      }
    },
  },
};
</script>

<style scoped></style>
