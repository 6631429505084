<template>
  <div v-if="check.loader == true">
    <div v-for="number in getNumbers()" :key="number">
      <v-skeleton-loader class="my-4" type="article"></v-skeleton-loader>
    </div>
  </div>
  <div
    v-else-if="check.loader == false && check.noResponse == true"
    class="d-flex flex-column justify-center align-center"
    style="min-height: 500px; max-height: 500px"
  >
    <v-img
      contain
      aspect-ratio="1"
      src="@/assets/under-construction.png"
      width="150"
      max-width="150"
      max-height="150"
      height="150"
    ></v-img>
    <div>
      <span
        class="ma-0 pa-0 fs-14 mt-2 fw-400 red--text font-weight-bold d-flex justify-center"
        >Hold on a sec! Something’s not quite right.</span
      >
      <span class="fs-14 mt-2 fw-400">
        Give the page a quick refresh or notify the administrator to help you
        sort things out.
      </span>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    check: {
      type: Object,
    },
    portalName: {
      type: String,
    },
    qty: {
      type: String,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  beforeDestroy() {},
  computed: {},
  methods: {
    getNumbers() {
      return Array.from({ length: Number(this.qty) }, (_, index) => index + 1);
    },
  },
};
</script>

<style scoped></style>
